// ----------------------------------------------------------------------------------------------------------------//
import './style.css'
import './loading.css'
import './menu.css'
import 'bootstrap-5.2.3/css/bootstrap.min.css';

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { HalfFloatType, TorusKnotGeometry, RGBADepthPacking } from "three";
import { RenderPass, EffectComposer, DepthPass, KernelSize, GodRaysEffect, EffectPass } from 'postprocessing';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';


const TWEEN = require('@tweenjs/tween.js');

//Door
const doorUrl = new URL('../static/assets/zcity_door_in_animation.glb', import.meta.url);

//Pointer
const pointerUrl = new URL('../static/assets/pointer.glb', import.meta.url);
const point_modalUrl = new URL('../static/assets/point_modal.glb', import.meta.url);

//Building
const zcityDesign2Url = new URL('../static/assets/zcityDesign2.glb', import.meta.url);
const zLogoUrl = new URL('../static/assets/z_logo.glb', import.meta.url);
const fountainUrl = new URL('../static/assets/fountain.glb', import.meta.url);
const spin_n_winUrl = new URL('../static/assets/spin_n_win.glb', import.meta.url);
const cloudUrl = new URL('../static/assets/cloud.glb', import.meta.url);

//Transport
const zVanUrl = new URL('../static/assets/z_van.glb', import.meta.url);

//Character
const characterzcityUrl = new URL('../static/assets/character_zcity.glb', import.meta.url);
const characterzcity2Url = new URL('../static/assets/character_zcity2.glb', import.meta.url);
const character1Url = new URL('../static/assets/character_1.glb', import.meta.url);
const character2Url = new URL('../static/assets/character_2.glb', import.meta.url);
const character3Url = new URL('../static/assets/character_3.glb', import.meta.url);
const character4Url = new URL('../static/assets/character_4.glb', import.meta.url);
const character5Url = new URL('../static/assets/character_5.glb', import.meta.url);
const character6Url = new URL('../static/assets/character_6.glb', import.meta.url);
const character7Url = new URL('../static/assets/character_7.glb', import.meta.url);
const character8Url = new URL('../static/assets/character_8.glb', import.meta.url);
const character9Url = new URL('../static/assets/character_9.glb', import.meta.url);
const character10Url = new URL('../static/assets/character_10.glb', import.meta.url);
const character11Url = new URL('../static/assets/character_11.glb', import.meta.url);
const character12Url = new URL('../static/assets/character_12.glb', import.meta.url);
const character13Url = new URL('../static/assets/character_13.glb', import.meta.url);
const character14Url = new URL('../static/assets/character_14.glb', import.meta.url);
const character15Url = new URL('../static/assets/character_15.glb', import.meta.url);
const character16Url = new URL('../static/assets/character_16.glb', import.meta.url);
const character17Url = new URL('../static/assets/character_17.glb', import.meta.url);
const character18Url = new URL('../static/assets/character_18.glb', import.meta.url);
const character19Url = new URL('../static/assets/character_19.glb', import.meta.url);
const character20Url = new URL('../static/assets/character_20.glb', import.meta.url);
const character21Url = new URL('../static/assets/character_21.glb', import.meta.url);
const character22Url = new URL('../static/assets/character_22.glb', import.meta.url);
const character23Url = new URL('../static/assets/character_23.glb', import.meta.url);
const character24Url = new URL('../static/assets/character_24.glb', import.meta.url);
const character25Url = new URL('../static/assets/character_25.glb', import.meta.url);
const character26Url = new URL('../static/assets/character_26.glb', import.meta.url);
const character27Url = new URL('../static/assets/character_27.glb', import.meta.url);

const interiorZstoreUrl = new URL('../static/assets/interior_zstore.glb', import.meta.url);
const interiorTazteUrl = new URL('../static/assets/interior_tazte.glb', import.meta.url);
const interiorEnogyUrl = new URL('../static/assets/interior_enogy.glb', import.meta.url);
const interiorJoinusUrl = new URL('../static/assets/interior_career.glb', import.meta.url);
const interiorZmemberUrl = new URL('../static/assets/interior_zmember.glb', import.meta.url);

//Ground
const groundUrl = new URL('../static/assets/ground1.glb', import.meta.url);
const forestUrl = new URL('../static/assets/forest.glb', import.meta.url);

// Create Scene----------------------------------------------------------------------------------------------------------------//
const scene = new THREE.Scene();
const scene2 = new THREE.Scene();
const depthScene = new THREE.Scene();


// Loader / TweenJS / Orbit Control----------------------------------------------------------------------------------------------------------------//
/**
 * Loaders
 */
const loadingManager = new THREE.LoadingManager();

const progressContainer = document.getElementById("progress");
const progressBar = document.getElementById("progress-bar");

loadingManager.onProgress = function (url, loaded, total) {
    progressBar.style.width = (loaded / total) * 100 + "%";
};

loadingManager.onLoad = function (url, loaded, total) {
    progressContainer.style.display = "none";
    document.getElementById("start-button").style.display = "inline-block";
};

const assetLoader = new GLTFLoader(loadingManager);

// Sizes / Renderer / Camera & Camera Position / Orbit Control----------------------------------------------------------------------------------------------------------------//
//----- Basic Setup -----\\
/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
};

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: document.querySelector('canvas.webgl'),
    antialias: true
});

//
renderer.physicallyCorrectLights = false
renderer.outputEncoding = THREE.sRGBEncoding
renderer.toneMapping = THREE.CustomToneMapping
// renderer.toneMappingExposure = 1
//
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

let container;
container = document.createElement('div');
document.body.appendChild(container);

// Create Cameara
const camera = new THREE.PerspectiveCamera(
    65,
    window.innerWidth / window.innerHeight,
    0.1,
    10000
);

// camera.position.set(-250, 420, 400);
camera.position.set(440, 45, 0);
camera.lookAt(scene.position);

/**
 * Orbit Controls
 */
const controls = new OrbitControls(camera, renderer.domElement);
controls.target.set(0, 0, 0);
controls.enablePan = false;
controls.enableDamping = true;
controls.rotateSpeed = 0.16;


// Renderer  across all loaders that use FileLoader,set
THREE.Cache.enabled = true;

// 3D Model / Door ------------------------------------------------------------------------------------//
/**
 * 3D Modeling 
 */

// Door in Scene
var doorShow;
var mixer_doorShow;
var openActionShow;
assetLoader.load(doorUrl.href, function (gltf) {
    doorShow = gltf.scene;
    doorShow.scale.set(0.4, 0.4, 0.4);
    doorShow.position.set(400, 5, 0);
    // Playing Animation
    mixer_doorShow = new THREE.AnimationMixer(doorShow);
    openActionShow = mixer_doorShow.clipAction(gltf.animations[0]);
    openActionShow.timeScale = 0.50;
    openActionShow.setLoop(THREE.LoopOnce);
    mixer_doorShow.addEventListener('finished', (event) => {
        scene.remove(doorShow);
    });
    scene.add(doorShow);
    doorShow.visible = true;
});

// Door in Depth Scene
var doorHide;
var mixer_doorHide;
var openActionHide;
assetLoader.load(doorUrl.href, function (gltf) {
    doorHide = gltf.scene;
    doorHide.scale.set(0.5, 0.5, 0.5);
    doorHide.position.set(400, 5, 0);
        // Playing Animation
    mixer_doorHide = new THREE.AnimationMixer(doorHide);
    openActionHide = mixer_doorHide.clipAction(gltf.animations[0]);
    openActionHide.timeScale = 0.55;
    openActionHide.setLoop(THREE.LoopOnce);
    depthScene.add(doorHide);
});



//Mesh / 3D Model / Animation ----------------------------------------------------------------------------------------------------------------//

// Main Building Design 2
let mixer_zcityDesign2
assetLoader.load(zcityDesign2Url.href, function (gltf) {
    const model_zcityDesign2 = gltf.scene;
    model_zcityDesign2.position.set(0, 1.5, 0);
    model_zcityDesign2.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = true;
    })
    scene.add(model_zcityDesign2);

    //Playing Animation
    mixer_zcityDesign2 = new THREE.AnimationMixer(model_zcityDesign2);
    const idleAction = mixer_zcityDesign2.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();

    //Character Missing Issue
    gltf.scene.traverse( function( object ) {

        object.frustumCulled = false;
    
    } );
});

// Z-Logo Building
assetLoader.load(zLogoUrl.href, function (gltf) {
    const zLogo = gltf.scene;
    zLogo.position.set(0, 3, 0);
    zLogo.rotateY(30);
    zLogo.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = true;
    })
    aimbox3.add(zLogo);
});

// Aim box link with Z-logo //
const geo_aimbox3 = new THREE.BoxGeometry(0.001, 0.001, 0.001);
const met_aimbox3 = new THREE.MeshLambertMaterial();
const aimbox3 = new THREE.Mesh(geo_aimbox3, met_aimbox3);
aimbox3.position.set(0, 0, 0);
scene.add(aimbox3);

assetLoader.load(zLogoUrl.href, function (gltf) {
    const zLogoUrl = gltf.scene;
    zLogoUrl.position.set(0, 3, 0);
    zLogoUrl.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = true;
    })
});

// Spin&Win
let mixer_spin_n_win;
assetLoader.load(spin_n_winUrl.href, function (gltf) {
    const model_spin_n_win = gltf.scene;
    scene.add(model_spin_n_win);
    model_spin_n_win.scale.set(1, 1, 1)
    model_spin_n_win.rotateY(0);
    // Receive and Cast Shadow
    model_spin_n_win.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_spin_n_win.position.set(-36, 1.3, 3);
    //Playing Animation
    mixer_spin_n_win = new THREE.AnimationMixer(model_spin_n_win);
    const idleAction = mixer_spin_n_win.clipAction(gltf.animations[0]);
    idleAction.timeScale = .5;
    idleAction.play();
});

// Fountain
let mixer_fountain;
assetLoader.load(fountainUrl.href, function (gltf) {
    const model_foutain = gltf.scene;
    scene.add(model_foutain);
    model_foutain.scale.set(1, 1, 1)
    model_foutain.rotateY(0);
    // Receive and Cast Shadow
    model_foutain.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_foutain.position.set(0.5, 1.6, 39.5);
    //Playing Animation
    mixer_fountain = new THREE.AnimationMixer(model_foutain);
    const idleAction = mixer_fountain.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1.6;
    idleAction.play();
});

// Cloud
let mixer_cloud;
assetLoader.load(cloudUrl.href, function (gltf) {
    const model_cloud = gltf.scene;
    scene.add(model_cloud);
    model_cloud.scale.set(1, 1, 1)
    model_cloud.rotateY(0);
    // Receive and Cast Shadow
    model_cloud.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_cloud.position.set(0, 0, 0);
    //Playing Animation
    mixer_cloud = new THREE.AnimationMixer(model_cloud);
    const idleAction = mixer_cloud.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1.6;
    idleAction.play();
});

// Ground
// const base_geo = new THREE.CylinderGeometry();
// const base_mat = new THREE.MeshStandardMaterial({ color: 0x92B451 });
// const base = new THREE.Mesh(base_geo, base_mat);
// scene.add(base);
// // base.castShadow = true;
// // base.receiveShadow = true;
// base.scale.set(4000.00, 0.1, 4000.00);
// base.position.set(0, 1, 0);

// assetLoader.load(groundUrl.href, function (gltf) {
//     const model_ground = gltf.scene;
//     model_ground.position.set(0, -180, 0);
//     model_ground.scale.set(1, 1, 1)
//     // model_ground.scale.set(1, 1, 1)
//     model_ground.traverse(function (node) {
//         if (node.isMesh)
//             node.castShadow = true;
//         node.receiveShadow = false;
//     })
//     scene.add(model_ground);
// });

// // Forest
// assetLoader.load(forestUrl.href, function (gltf) {
//     const model_forest = gltf.scene;
//     model_forest.position.set(0, 0, 0);
//     model_forest.scale.set(1, 1, 1)
//     model_forest.traverse(function (node) {
//         if (node.isMesh)
//             node.castShadow = true;
//         node.receiveShadow = false;
//     })
//     scene.add(model_forest);
// });

//Mesh / 3D Model / Animation ----------------------------------------------------------------------------------------------------------------//
/*
 * 3D Modeling with Animation
 */
// SET model and mesh//

// Main Character Z-City (Walk Around Z-City - Blue)
var character;
var mixer_character;
var walkAction;
assetLoader.load(characterzcityUrl.href, function (gltf) {
    character = gltf.scene;
    character.scale.set(1, 1, 1);
    character.position.set(0, 1.65, 0);

    // Playing Animation
    mixer_character = new THREE.AnimationMixer(character);
    walkAction = mixer_character.clipAction(gltf.animations[0]);
    walkAction.timeScale = 0;
    walkAction.play();

    // Receive and Cast Shadow
    gltf.scene.traverse(function (node) {
        if (node.isMesh) {
            node.castShadow = true;
            node.receiveShadow = true;
        }
    });
    scene.add(character);
});

// Character Z-City 2 (Walk Around Z-City - White)
let mixer_characterzcity2;
assetLoader.load(characterzcity2Url.href, function (gltf) {
    const model_characterzcity2 = gltf.scene;
    model_characterzcity2.scale.set(1, 1, 1);
    // model_character7.rotateY(-1.47);
    scene.add(model_characterzcity2);
    // Receive and Cast Shadow
    model_characterzcity2.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = true;
    });
    model_characterzcity2.position.set(20, 0.8, 43.5);
    // Playing Animation
    const clips = gltf.animations;
    mixer_characterzcity2 = new THREE.AnimationMixer(model_characterzcity2);
    const runClip = THREE.AnimationClip.findByName(clips, 'Walk');
    const runAction = mixer_characterzcity2.clipAction(runClip);
    runAction.timeScale = 1.5;
    runAction.play();
    aimbox2.add(model_characterzcity2);
}, undefined, function (error) {
});

// Character 1 (Female-Welcome-Blue)
let mixer_character1;
assetLoader.load(character1Url.href, function (gltf) {
    const model_character1 = gltf.scene;
    scene.add(model_character1);
    model_character1.scale.set(1, 1, 1);
    model_character1.rotateY(108.5);
    // Receive and Cast Shadow
    model_character1.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_character1.position.set(-41.5, 1.5, -12.5);
    //Playing Animation
    mixer_character1 = new THREE.AnimationMixer(model_character1);
    const idleAction = mixer_character1.clipAction(gltf.animations[0]);
    idleAction.timeScale = 0.8;
    idleAction.play();
});

// Character 2 (Male-Dance-Yellow)
let mixer_character2;
assetLoader.load(character2Url.href, function (gltf) {
    const model_character2 = gltf.scene;
    scene.add(model_character2);
    model_character2.scale.set(1, 1, 1)
    model_character2.rotateY(-179.8);
    // Receive and Cast Shadow
    model_character2.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_character2.position.set(-35, 1.5, -20);
    //Playing Animation
    mixer_character2 = new THREE.AnimationMixer(model_character2);
    const idleAction = mixer_character2.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1.5;
    idleAction.play();
});

// Character 3 (Female-Dance-White)
let mixer_character3;
assetLoader.load(character3Url.href, function (gltf) {
    const model_character3 = gltf.scene;
    scene.add(model_character3);
    model_character3.scale.set(1, 1, 1);
    model_character3.rotateY(3);
    // Receive and Cast Shadow
    model_character3.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_character3.position.set(8, 1.5, 41.5);
    //Playing Animation
    mixer_character3 = new THREE.AnimationMixer(model_character3);
    const idleAction = mixer_character3.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1.5;
    idleAction.play();
});

// Character 4 (Female-Welcome-Blue)
let mixer_character4;
assetLoader.load(character4Url.href, function (gltf) {
    const model_character4 = gltf.scene;
    scene.add(model_character4);
    model_character4.scale.set(1, 1, 1);
    model_character4.rotateY(5.5);
    // Receive and Cast Shadow
    model_character4.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_character4.position.set(26, 1.6, -35);
    //Playing Animation
    mixer_character4 = new THREE.AnimationMixer(model_character4);
    const idleAction = mixer_character4.clipAction(gltf.animations[0]);
    idleAction.timeScale = 0.8;
    idleAction.play();
});

// Character 5 (Female-Jump-Yellow)
let mixer_character5;
assetLoader.load(character5Url.href, function (gltf) {
    const model_character5 = gltf.scene;
    scene.add(model_character5);
    model_character5.scale.set(1, 1, 1);
    model_character5.rotateY(0);
    // Receive and Cast Shadow
    model_character5.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_character5.position.set(35, 1.6, -25);
    //Playing Animation
    mixer_character5 = new THREE.AnimationMixer(model_character5);
    const idleAction = mixer_character5.clipAction(gltf.animations[0]);
    idleAction.timeScale = 0.8;
    idleAction.play();
});


// Character 7 (Female-Wave-Blue)
let mixer_character7;
assetLoader.load(character7Url.href, function (gltf) {
    const model_character7 = gltf.scene;
    scene.add(model_character7);
    model_character7.scale.set(1, 1, 1);
    model_character7.rotateY(2.8);
    // Receive and Cast Shadow
    model_character7.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_character7.position.set(41, 1.6, 11);
    //Playing Animation
    mixer_character7 = new THREE.AnimationMixer(model_character7);
    const idleAction = mixer_character7.clipAction(gltf.animations[0]);
    idleAction.timeScale = .6;
    idleAction.play();
});

// Character 8 (Female-Jump)
let mixer_character8;
assetLoader.load(character8Url.href, function (gltf) {
    const model_character8 = gltf.scene;
    scene.add(model_character8);
    model_character8.scale.set(1, 1, 1);
    model_character8.rotateY(0);
    // Receive and Cast Shadow
    model_character8.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_character8.position.set(-30, 1.6, 23);
    //Playing Animation
    mixer_character8 = new THREE.AnimationMixer(model_character8);
    const idleAction = mixer_character8.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();
});

// Character 9 (Male-Jump-Yellow)
let mixer_character9;
assetLoader.load(character9Url.href, function (gltf) {
    const model_character9 = gltf.scene;
    scene.add(model_character9);
    model_character9.scale.set(1, 1, 1);
    model_character9.rotateY(0);
    // Receive and Cast Shadow
    model_character9.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_character9.position.set(-30, 1.6, 27.5);
    //Playing Animation
    mixer_character9 = new THREE.AnimationMixer(model_character9);
    const idleAction = mixer_character9.clipAction(gltf.animations[0]);
    idleAction.timeScale = .6;
    idleAction.play();
});

// Character 10 (Male-Jump)
let mixer_character10;
assetLoader.load(character10Url.href, function (gltf) {
    const model_character10 = gltf.scene;
    scene.add(model_character10);
    model_character10.scale.set(1, 1, 1);
    model_character10.rotateY(8);
    // Receive and Cast Shadow
    model_character10.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_character10.position.set(-10.5, 1.6, -42);
    //Playing Animation
    mixer_character10 = new THREE.AnimationMixer(model_character10);
    const idleAction = mixer_character10.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();
});

// Character 11 (Gather-Talk)
let mixer_character11;
assetLoader.load(character11Url.href, function (gltf) {
    const model_character11 = gltf.scene;
    scene.add(model_character11);
    model_character11.scale.set(1, 1, 1);
    model_character11.rotateY(0);
    // Receive and Cast Shadow
    model_character11.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_character11.position.set(-3, 1.6, -38);
    //Playing Animation
    mixer_character11 = new THREE.AnimationMixer(model_character11);
    const idleAction = mixer_character11.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();
});

// Character 12 (Female-Move-Black)
let mixer_character12;
assetLoader.load(character12Url.href, function (gltf) {
    const model_character12 = gltf.scene;
    scene.add(model_character12);
    model_character12.scale.set(1, 1, 1);
    model_character12.rotateY(0);
    // Receive and Cast Shadow
    model_character12.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_character12.position.set(34, 1.6, 24);
    //Playing Animation
    mixer_character12 = new THREE.AnimationMixer(model_character12);
    const idleAction = mixer_character12.clipAction(gltf.animations[0]);
    idleAction.timeScale = .6;
    idleAction.play();
});

// Character 13 (Female-Move-Yellow)
let mixer_character13;
assetLoader.load(character13Url.href, function (gltf) {
    const model_character13 = gltf.scene;
    scene.add(model_character13);
    model_character13.scale.set(1, 1, 1);
    model_character13.rotateY(-3.5);
    // Receive and Cast Shadow
    model_character13.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_character13.position.set(-35.5, 1.6, 21.5);
    //Playing Animation
    mixer_character13 = new THREE.AnimationMixer(model_character13);
    const idleAction = mixer_character13.clipAction(gltf.animations[0]);
    idleAction.timeScale = .6;
    idleAction.play();
});

// Character 14 ( Spin Couple-White)
let mixer_character14;
assetLoader.load(character14Url.href, function (gltf) {
    const model_character14 = gltf.scene;
    scene.add(model_character14);
    model_character14.scale.set(1, 1, 1);
    model_character14.rotateY(1);
    // Receive and Cast Shadow
    model_character14.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_character14.position.set(-39.5, 1.6, -17.8);
    //Playing Animation
    mixer_character14 = new THREE.AnimationMixer(model_character14);
    const idleAction = mixer_character14.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();
});

// Character 15 (Female-Walk-White)
let mixer_character15;
assetLoader.load(character15Url.href, function (gltf) {
    const model_character15 = gltf.scene;
    scene.add(model_character15);
    model_character15.scale.set(1, 1, 1);
    model_character15.rotateY(-5);
    // Receive and Cast Shadow
    model_character15.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_character15.position.set(-41, 1.6, 12);
    //Playing Animation
    mixer_character15 = new THREE.AnimationMixer(model_character15);
    const idleAction = mixer_character15.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();
});

// Character 16 (Couple-White)
let mixer_character16;
assetLoader.load(character16Url.href, function (gltf) {
    const model_character16 = gltf.scene;
    scene.add(model_character16);
    model_character16.scale.set(1, 1, 1);
    model_character16.rotateY(5.5);
    // Receive and Cast Shadow
    model_character16.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_character16.position.set(35, 1.6, -20.5);
    //Playing Animation
    mixer_character16 = new THREE.AnimationMixer(model_character16);
    const idleAction = mixer_character16.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();
});

// Character 17 (Three People)
let mixer_character17;
assetLoader.load(character17Url.href, function (gltf) {
    const model_character17 = gltf.scene;
    scene.add(model_character17);
    model_character17.scale.set(1, 1, 1);
    model_character17.rotateY(-3.8);
    // Receive and Cast Shadow
    model_character17.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_character17.position.set(-23, 1.6, 36);
    //Playing Animation
    mixer_character17 = new THREE.AnimationMixer(model_character17);
    const idleAction = mixer_character17.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();
});

// Character 18 (Old-Man)
let mixer_character18;
assetLoader.load(character18Url.href, function (gltf) {
    const model_character18 = gltf.scene;
    scene.add(model_character18);
    model_character18.scale.set(1, 1, 1);
    model_character18.rotateY(-.5);
    // Receive and Cast Shadow
    model_character18.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_character18.position.set(39, 1.6, 18);
    //Playing Animation
    mixer_character18 = new THREE.AnimationMixer(model_character18);
    const idleAction = mixer_character18.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();
});

// Character 19 (Spin)
let mixer_character19;
assetLoader.load(character19Url.href, function (gltf) {
    const model_character19 = gltf.scene;
    scene.add(model_character19);
    model_character19.scale.set(1, 1, 1);
    model_character19.rotateY(-1.5);
    // Receive and Cast Shadow
    model_character19.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_character19.position.set(-56, 1.6, -0.8);
    //Playing Animation
    mixer_character19 = new THREE.AnimationMixer(model_character19);
    const idleAction = mixer_character19.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();
});

// Character 20 (Spin)
let mixer_character20;
assetLoader.load(character20Url.href, function (gltf) {
    const model_character20 = gltf.scene;
    scene.add(model_character20);
    model_character20.scale.set(1, 1, 1);
    model_character20.rotateY(-1.25);
    // Receive and Cast Shadow
    model_character20.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_character20.position.set(-55.5, 1.6, 11);
    //Playing Animation
    mixer_character20 = new THREE.AnimationMixer(model_character20);
    const idleAction = mixer_character20.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();
});

// Character 21 (Zmember)
let mixer_character21;
assetLoader.load(character21Url.href, function (gltf) {
    const model_character21 = gltf.scene;
    scene.add(model_character21);
    model_character21.scale.set(1, 1, 1);
    model_character21.rotateY(-2.5);
    // Receive and Cast Shadow
    model_character21.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_character21.position.set(28.4, 1.6, 32);
    //Playing Animation
    mixer_character21 = new THREE.AnimationMixer(model_character21);
    const idleAction = mixer_character21.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();
});

// Character 22 (Zstore)
let mixer_character22;
assetLoader.load(character22Url.href, function (gltf) {
    const model_character22 = gltf.scene;
    scene.add(model_character22);
    model_character22.scale.set(1, 1, 1);
    model_character22.rotateY(-1.5);
    // Receive and Cast Shadow
    model_character22.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_character22.position.set(38.7, 1.6, -19.65);
    //Playing Animation
    mixer_character22 = new THREE.AnimationMixer(model_character22);
    const idleAction = mixer_character22.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();
});

// Character 23 (enogy)
let mixer_character23;
assetLoader.load(character23Url.href, function (gltf) {
    const model_character23 = gltf.scene;
    scene.add(model_character23);
    model_character23.scale.set(1, 1, 1);
    model_character23.rotateY(-2.3);
    // Receive and Cast Shadow
    model_character23.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_character23.position.set(-29, 1.5, -48.45);
    //Playing Animation
    mixer_character23 = new THREE.AnimationMixer(model_character23);
    const idleAction = mixer_character23.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();
});

// Character 24 (Zstore)
let mixer_character24;
assetLoader.load(character24Url.href, function (gltf) {
    const model_character24 = gltf.scene;
    scene.add(model_character24);
    model_character24.scale.set(1, 1, 1);
    model_character24.rotateY(-1);
    // Receive and Cast Shadow
    model_character24.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_character24.position.set(38.75, 1.1, -16.5);
    //Playing Animation
    mixer_character24 = new THREE.AnimationMixer(model_character24);
    const idleAction = mixer_character24.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();
});

// Character 25 (Enogy)
let mixer_character25;
assetLoader.load(character25Url.href, function (gltf) {
    const model_character25 = gltf.scene;
    scene.add(model_character25);
    model_character25.scale.set(1, 1, 1);
    model_character25.rotateY(-2.25);
    // Receive and Cast Shadow
    model_character25.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_character25.position.set(-44.5, 1.5, -35.75);
    //Playing Animation
    mixer_character25 = new THREE.AnimationMixer(model_character25);
    const idleAction = mixer_character25.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();
});

// Character 26 (Enogy)
let mixer_character26;
assetLoader.load(character26Url.href, function (gltf) {
    const model_character26 = gltf.scene;
    scene.add(model_character26);
    model_character26.scale.set(1, 1, 1);
    model_character26.rotateY(-2.25);
    // Receive and Cast Shadow
    model_character26.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_character26.position.set(-48, 1.5, -30);
    //Playing Animation
    mixer_character26 = new THREE.AnimationMixer(model_character26);
    const idleAction = mixer_character26.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();
});

// Character 27 (Spin Male-Yellow)
let mixer_character27;
assetLoader.load(character27Url.href, function (gltf) {
    const model_character27 = gltf.scene;
    scene.add(model_character27);
    model_character27.scale.set(1, 1, 1);
    model_character27.rotateY(1.5);
    // Receive and Cast Shadow
    model_character27.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_character27.position.set(-40.5, 1.5, 2.95);
    //Playing Animation
    mixer_character27 = new THREE.AnimationMixer(model_character27);
    const idleAction = mixer_character27.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();
});

// VAN 
let mixer_van;
assetLoader.load(zVanUrl.href, function (gltf) {
    const model_van = gltf.scene;
    model_van.scale.set(1, 1, 1);
    // model_van.rotateY(-1.47);
    scene.add(model_van);
    // Receive and Cast Shadow
    model_van.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = true;
    });
    model_van.position.set(0, 0.6, 47.6);
    // Playing Animation
    const clips = gltf.animations;
    mixer_van = new THREE.AnimationMixer(model_van);
    const runClip = THREE.AnimationClip.findByName(clips, 'Walk');
    const runAction = mixer_van.clipAction(runClip);
    //runAction.timeScale = 1.5;
    //runAction.play();
    aimbox2.add(model_van);
}, undefined, function (error) {
});

//target to character who rotate around the city
const geo_aimbox2 = new THREE.BoxGeometry(0.001, 0.001, 0.001);
const met_aimbox2 = new THREE.MeshLambertMaterial({ color: Math.random() * 0xffffff });
const aimbox2 = new THREE.Mesh(geo_aimbox2, met_aimbox2);
aimbox2.position.set(0, 1, 0);
scene.add(aimbox2);
//END SET//



// Lighting ----------------------------------------------------------------------------------------------------------------//
/**
 * Lighting
 */

// Directional Light
let shadowMapSize = 60;
const sunLight = new THREE.DirectionalLight(0xffffff, 0.7);
sunLight.position.set(30, 100, 0);
sunLight.visible = true;
sunLight.castShadow = true;
sunLight.shadow.mapSize.width = 4096;
sunLight.shadow.mapSize.height = 4096;
sunLight.shadow.camera.near = 0.6;
sunLight.shadow.camera.far = shadowMapSize * 2;
sunLight.shadow.camera.top = shadowMapSize;
sunLight.shadow.camera.bottom = -shadowMapSize;
sunLight.shadow.camera.left = -shadowMapSize;
sunLight.shadow.camera.right = shadowMapSize;
sunLight.shadow.normalBias = 0.02;
scene.add(sunLight);
scene.add(sunLight.target);

// // const helper = new THREE.CameraHelper( sunLight.shadow.camera );
// // scene.add( helper );

// // Hemisphere Light
const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.4);
scene.add(hemiLight);

// Ambient Light
const ambientLight = new THREE.AmbientLight(0xF4E46B, 0.2);
scene.add(ambientLight);

// Light follow camera
// const pointLight1 = new THREE.PointLight(0xffffff, 0.3, 0, 0.1);
// scene.add(pointLight1);



// God Rays / Composer ----------------------------------------------------------------------------------------------------------------//
/**
 * Post Processing - God Rays
 */
const composer = new EffectComposer(renderer, {
    frameBufferType: HalfFloatType
});

const pointLight = new THREE.PointLight(0xbbffff, 0.8);
pointLight.position.set(395, 35, 0); // God Light Position
// pointLight.target = scene;
// scene.add(pointLight);

// Godlight.
const godLightMaterial = new THREE.MeshBasicMaterial({ color: 0xbbffff, fog: false });
const godLightGeometry = new THREE.BoxGeometry(0.1, 60, 60);
const godLight = new THREE.Mesh(godLightGeometry, godLightMaterial);
godLight.position.copy(pointLight.position);
godLight.frustumCulled = false;
// The godLight mesh is not added to the scene to hide hard geometry edges.
// scene.add(godLight);
godLight.matrixAutoUpdate = false;
godLight.updateMatrix();

//Mesh after God Light
const torusKnot = new THREE.Mesh(
    new TorusKnotGeometry(25, 50),
    new THREE.MeshPhongMaterial()
);
torusKnot.position.set(240, 17, 0); // Hide God Light Position
depthScene.add(torusKnot);

const godRaysEffect = new GodRaysEffect(camera, godLight, {
    kernelSize: KernelSize.SMALL,
    height: 400.00,
    density: 0.95,
    decay: 0.90,
    weight: 0.85,
    exposure: 0.75,
    samples: 60.00,
    clampMax: 100.00
});

const renderPass = new RenderPass(scene, camera);
const effectPass = new EffectPass(camera, godRaysEffect);

const depthPass = new DepthPass(depthScene, camera);
effectPass.setDepthTexture(depthPass.renderTarget.texture, RGBADepthPacking);

composer.addPass(depthPass);
composer.addPass(renderPass);
composer.addPass(effectPass);


// loading and start animation ----------------------------------------------------------------------------------------------------------------//
//loading and start animation
document.getElementById("start-button").onclick = function () {
    document.getElementById("loadingscreen").classList.add("hidden");

    // TWEEN JS
    new TWEEN.Tween(camera.position)
        .to({ x: 75, y: 19, z: 0 }, 9000) // default 9000
        .easing(TWEEN.Easing.Quartic.InOut)
        .start()
        ;
    openActionHide.play();
    openActionShow.play();

    scene2.visible = false;

    //orbit control(angle and distance) run after start TWEEN
    // Default minPolarAngle = 0 / maxPolarAngle = Math.PI
    controls.minPolarAngle = Math.PI / 3.90;
    controls.maxPolarAngle = Math.PI / 2.15;
    controls.minDistance = 55;
    controls.maxDistance = 80;
    // controls.autoRotate = true;
    // controls.autoRotateSpeed = -3;
};

// Raycaster and mouse event ----------------------------------------------------------------------------------------------------------------//
const raycaster = new THREE.Raycaster();
const mousePos = new THREE.Vector2();

// // CASE 1 Mouse Click Event
// window.addEventListener('click', onDocumentMouseDown, false);
// function onDocumentMouseDown(event) {

//     mousePos.x = (event.clientX / this.window.innerWidth) * 2 - 1;
//     mousePos.y = -(event.clientY / this.window.innerHeight) * 2 + 1;
//     raycaster.setFromCamera(mousePos, camera);

// };

// Environments ----------------------------------------------------------------------------------------------------------------//
// ----- Sky box -----\\
let materialArray = [];
let texture_ft = new THREE.TextureLoader().load('skybox/px1.png');
let texture_bk = new THREE.TextureLoader().load('skybox/nx1.png');
let texture_up = new THREE.TextureLoader().load('skybox/py1.png');
let texture_dn = new THREE.TextureLoader().load('skybox/ny1.png');
let texture_rt = new THREE.TextureLoader().load('skybox/pz1.png');
let texture_lf = new THREE.TextureLoader().load('skybox/nz1.png');

materialArray.push(new THREE.MeshBasicMaterial({ map: texture_ft }));
materialArray.push(new THREE.MeshBasicMaterial({ map: texture_bk }));
materialArray.push(new THREE.MeshBasicMaterial({ map: texture_up }));
materialArray.push(new THREE.MeshBasicMaterial({ map: texture_dn }));
materialArray.push(new THREE.MeshBasicMaterial({ map: texture_rt }));
materialArray.push(new THREE.MeshBasicMaterial({ map: texture_lf }));

for (let i = 0; i < 6; i++)
    materialArray[i].side = THREE.BackSide;
let skyboxGeo = new THREE.BoxGeometry(8000, 8000, 8000);
let skybox = new THREE.Mesh(skyboxGeo, materialArray);
skybox.position.set(0, -80, 0);
scene.add(skybox);



// Interior Z-Store
let interior_zstore;
let mixer_interiorZstore;
assetLoader.load(interiorZstoreUrl.href, function (gltf) {
    interior_zstore = gltf.scene;
    scene2.add(interior_zstore);
    // Receive and Cast Shadow
    interior_zstore.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = true;
    });
    interior_zstore.position.set(0, -171, 0);
    interior_zstore.visible = false;
    //Playing Animation
    mixer_interiorZstore = new THREE.AnimationMixer(interior_zstore);
    const idleAction = mixer_interiorZstore.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();
});



// Interior Tazte
let interior_tazte;
let mixer_interiorTazte;
assetLoader.load(interiorTazteUrl.href, function (gltf) {
    interior_tazte = gltf.scene;
    scene2.add(interior_tazte);
    // Receive and Cast Shadow
    interior_tazte.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = true;
    });
    interior_tazte.position.set(0, -220, 0);
    interior_tazte.rotateY(0);
    interior_tazte.visible = false;
    //Playing Animation
    mixer_interiorTazte = new THREE.AnimationMixer(interior_tazte);
    const idleAction = mixer_interiorTazte.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();
});



// Interior Enogy
let interior_enogy
let mixer_interiorEnogy;
assetLoader.load(interiorEnogyUrl.href, function (gltf) {
    interior_enogy = gltf.scene;
    scene2.add(interior_enogy);
    // Receive and Cast Shadow
    interior_enogy.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = true;
    });
    interior_enogy.position.set(0, -275, 0);
    interior_enogy.visible = false;
    //Playing Animation
    mixer_interiorEnogy = new THREE.AnimationMixer(interior_enogy);
    const idleAction = mixer_interiorEnogy.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();
});



// Interior Join Us
let interior_joinus;
let mixer_interiorJoinUs;
assetLoader.load(interiorJoinusUrl.href, function (gltf) {
    interior_joinus = gltf.scene;
    scene2.add(interior_joinus);
    // Receive and Cast Shadow
    interior_joinus.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = true;
    });
    interior_joinus.position.set(0, -320, 0);
    interior_joinus.visible = false;
    //Playing Animation
    // mixer_interiorJoinUs = new THREE.AnimationMixer(interior_joinus);
    // const idleAction = mixer_interiorJoinUs.clipAction(gltf.animations[0]);
    // idleAction.timeScale = 1;
    // idleAction.play();
});



// Interior Z-Member
let interior_zmember;
let mixer_interiorZmember;
assetLoader.load(interiorZmemberUrl.href, function (gltf) {
    interior_zmember = gltf.scene;
    scene2.add(interior_zmember);
    // Receive and Cast Shadow
    interior_zmember.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = true;
    });
    interior_zmember.position.set(0, -370, 0);
    interior_zmember.visible = false;
    //Playing Animation
    mixer_interiorZmember = new THREE.AnimationMixer(interior_zmember);
    const idleAction = mixer_interiorZmember.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();
});



// ----Function---- //

// Define Geometry
var geometry = new THREE.BoxGeometry(1, 1, 1);
var material = new THREE.MeshPhongMaterial();

//Button Box
// Button Box: Z-Store (Swap Join Us Now)--------------------------------------------------------------------------------------------------------------------------------------------------------------
var btnbox4 = new THREE.Mesh(geometry, material);
btnbox4.position.set(40.5, 3, -0.6);
btnbox4.scale.set(4, 4, 4);
btnbox4.rotateY(0);
scene.add(btnbox4);
btnbox4.visible = false;

var btnbox4e = new THREE.Mesh(geometry, material);
btnbox4e.position.set(1, -170, -17);
btnbox4e.scale.set(3, 3, 3);
scene2.add(btnbox4e);
btnbox4e.visible = false;

// Z-Store Pointer 
let mixer_pointer4;
assetLoader.load(pointerUrl.href, function (gltf) {
    const model_pointer4 = gltf.scene;
    scene2.add(model_pointer4);
    model_pointer4.scale.set(2, 2, 2)
    model_pointer4.rotateY(1.6);
    //Receive and Cast Shadow
    model_pointer4.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_pointer4.position.set(1, -170, -17);
    //Playing Animation
    // mixer_pointer4 = new THREE.AnimationMixer(model_pointer4);
    // const idleAction = mixer_pointer4.clipAction(gltf.animations[0]);
    // idleAction.timeScale = 1;
    // idleAction.play();

});

// Z-Store Modal Point
let mixer_point_modal1;
assetLoader.load(point_modalUrl.href, function (gltf) {
    const model_point_modal1 = gltf.scene;
    scene2.add(model_point_modal1);
    model_point_modal1.scale.set(4.5, 4.5, 4.5)
    model_point_modal1.rotateY(0);
    //Receive and Cast Shadow
    model_point_modal1.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_point_modal1.position.set(1.5, -167.25, -7.8);
    //Playing Animation
    mixer_point_modal1 = new THREE.AnimationMixer(model_point_modal1);
    const idleAction = mixer_point_modal1.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();

});

// Button Box: Tazte --------------------------------------------------------------------------------------------------------------------------------------------------------------
var btnbox2 = new THREE.Mesh(geometry, material);
btnbox2.position.set(19.2, 3, -35.1);
btnbox2.scale.set(4, 4, 4);
btnbox2.rotateY(0.99);
scene.add(btnbox2);
btnbox2.visible = false;

var btnbox2e = new THREE.Mesh(geometry, material);
btnbox2e.position.set(1, -218.5, -17);
btnbox2e.scale.set(2, 2, 2);
scene2.add(btnbox2e);
btnbox2e.visible = false;

// Tazte Pointer
let mixer_pointer2;
assetLoader.load(pointerUrl.href, function (gltf) {
    const model_pointer2 = gltf.scene;
    scene2.add(model_pointer2);
    model_pointer2.scale.set(2, 2, 2)
    model_pointer2.rotateY(1.6);
    //Receive and Cast Shadow
    model_pointer2.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_pointer2.position.set(1, -219, -17);
    //Playing Animation
    mixer_pointer2 = new THREE.AnimationMixer(model_pointer2);
    const idleAction = mixer_pointer2.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();

});

// Tazte Modal Point
let mixer_point_modal2;
assetLoader.load(point_modalUrl.href, function (gltf) {
    const model_point_modal2 = gltf.scene;
    scene2.add(model_point_modal2);
    model_point_modal2.scale.set(3.8, 3.8, 3.8)
    model_point_modal2.rotateY(0);
    //Receive and Cast Shadow
    model_point_modal2.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_point_modal2.position.set(3.45, -214.3, -9.13);
    //Playing Animation
    mixer_point_modal2 = new THREE.AnimationMixer(model_point_modal2);
    const idleAction = mixer_point_modal2.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();

});

// Button Box: Enorgy --------------------------------------------------------------------------------------------------------------------------------------------------------------
var btnbox3 = new THREE.Mesh(geometry, material);
btnbox3.position.set(-28.9, 3, -29.9);
btnbox3.scale.set(4, 4, 4);
btnbox3.rotateY(-0.8);
scene.add(btnbox3);
btnbox3.visible = false;

var btnbox3e = new THREE.Mesh(geometry, material);
btnbox3e.position.set(1, -273.5, -17);
btnbox3e.scale.set(2, 2, 2);
scene2.add(btnbox3e);
btnbox3e.visible = false;

// Enorgy Pointer
let mixer_pointer3;
assetLoader.load(pointerUrl.href, function (gltf) {
    const model_pointer3 = gltf.scene;
    scene2.add(model_pointer3);
    model_pointer3.scale.set(2, 2, 2)
    model_pointer3.rotateY(1.6);
    //Receive and Cast Shadow
    model_pointer3.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_pointer3.position.set(1, -275, -17);
    //Playing Animation
    mixer_pointer3 = new THREE.AnimationMixer(model_pointer3);
    const idleAction = mixer_pointer3.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();

});

// Enorgy Modal Point
let mixer_point_modal3;
assetLoader.load(point_modalUrl.href, function (gltf) {
    const model_point_modal3 = gltf.scene;
    scene2.add(model_point_modal3);
    // model_point_modal3.scale.set(8, 8, 8) // Design 2
    model_point_modal3.scale.set(4, 4, 4)
    model_point_modal3.rotateY(0);
    //Receive and Cast Shadow
    model_point_modal3.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    // model_point_modal3.position.set(5.75, -269, 1.5); // Design 2
    model_point_modal3.position.set(5.75, -269, 1.35);
    //Playing Animation
    mixer_point_modal3 = new THREE.AnimationMixer(model_point_modal3);
    const idleAction = mixer_point_modal3.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();

});

// Button Box: Join Us (Swap Z-Store Now)--------------------------------------------------------------------------------------------------------------------------------------------------------------
var btnbox1 = new THREE.Mesh(geometry, material);
btnbox1.position.set(-21, 3, 35.56);
btnbox1.scale.set(4, 4, 4);
btnbox1.rotateY(1);
scene.add(btnbox1);
btnbox1.visible = false;

var btnbox1e = new THREE.Mesh(geometry, material);
btnbox1e.position.set(1, -319, -17);
btnbox1e.scale.set(2, 2, 2);
scene2.add(btnbox1e);
btnbox1e.visible = false;

// Join Us Pointer
let mixer_pointer1;
assetLoader.load(pointerUrl.href, function (gltf) {
    const model_pointer1 = gltf.scene;
    scene2.add(model_pointer1);
    model_pointer1.scale.set(2, 2, 2)
    model_pointer1.rotateY(1.6);
    //Receive and Cast Shadow
    model_pointer1.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_pointer1.position.set(1, -320, -17);
    //Playing Animation
    mixer_pointer1 = new THREE.AnimationMixer(model_pointer1);
    const idleAction = mixer_pointer1.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();

});

// Join Us Modal Point
// let mixer_point_modal4;
// assetLoader.load(point_modalUrl.href, function (gltf) {
//     const model_point_modal4 = gltf.scene;
//     scene2.add(model_point_modal4);
//     model_point_modal4.scale.set(3.8, 3.8, 3.8)
//     model_point_modal4.rotateY(0);
//     //Receive and Cast Shadow
//     model_point_modal4.traverse(function (node) {
//         if (node.isMesh)
//             node.castShadow = true;
//         node.receiveShadow = false;
//     })
//     model_point_modal4.position.set(4.25, -314.3, -8.5);
//     //Playing Animation
//     mixer_point_modal4 = new THREE.AnimationMixer(model_point_modal4);
//     const idleAction = mixer_point_modal4.clipAction(gltf.animations[0]);
//     idleAction.timeScale = 1;
//     idleAction.play();

// });

// Button Box: Z Member --------------------------------------------------------------------------------------------------------------------------------------------------------------
var btnbox5 = new THREE.Mesh(geometry, material);
btnbox5.position.set(18, 3, 37);
btnbox5.scale.set(4, 4, 4);
btnbox5.rotateY(-1.25);
scene.add(btnbox5);
btnbox5.visible = false;

var btnbox5e = new THREE.Mesh(geometry, material);
btnbox5e.position.set(1, -369, -17);
btnbox5e.scale.set(2, 2, 2);
scene2.add(btnbox5e);
btnbox5e.visible = false;

// Z Member Pointer
let mixer_pointer5;
assetLoader.load(pointerUrl.href, function (gltf) {
    const model_pointer5 = gltf.scene;
    scene2.add(model_pointer5);
    model_pointer5.scale.set(2, 2, 2)
    model_pointer5.rotateY(1.6);
    //Receive and Cast Shadow
    model_pointer5.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_pointer5.position.set(1, -370, -17);
    //Playing Animation
    mixer_pointer5 = new THREE.AnimationMixer(model_pointer5);
    const idleAction = mixer_pointer5.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();

});

// Z Member Modal Point
let mixer_point_modal5;
assetLoader.load(point_modalUrl.href, function (gltf) {
    const model_point_modal5 = gltf.scene;
    scene2.add(model_point_modal5);
    model_point_modal5.scale.set(4, 4, 4)
    model_point_modal5.rotateY(0);
    //Receive and Cast Shadow
    model_point_modal5.traverse(function (node) {
        if (node.isMesh)
            node.castShadow = true;
        node.receiveShadow = false;
    })
    model_point_modal5.position.set(1.4, -363.8, 5.5);
    //Playing Animation
    mixer_point_modal5 = new THREE.AnimationMixer(model_point_modal5);
    const idleAction = mixer_point_modal5.clipAction(gltf.animations[0]);
    idleAction.timeScale = 1;
    idleAction.play();

});



// 
// Mouse Click Event 
// 
// window.addEventListener('dblclick', onDocumentMouseDown, false);
window.addEventListener('click', onDocumentMouseDown, false);
function onDocumentMouseDown(event) {

    // event.preventDefault();

    mousePos.x = (event.clientX / this.window.innerWidth) * 2 - 1;
    mousePos.y = -(event.clientY / this.window.innerHeight) * 2 + 1;
    raycaster.setFromCamera(mousePos, camera);



    // Check for intersection with videoMesh
    var intersects = raycaster.intersectObject(videoMesh);
    if (intersects.length > 0) {
        window.open('https://www.youtube.com/watch?v=9UWIpIrqsQQ&t=85s&ab_channel=ZCITY', '_blank');
    }



    // Button Function: Z-Store--------------------------------------------------------------------------------------------------------------------------------------------------------------
    var intersects = raycaster.intersectObjects([btnbox1]);
    if (intersects.length > 0) {

        // Show overlay
        const overlay = document.querySelector('.overlay');
        overlay.classList.add('show');

        // Hide overlay after a delay
        setTimeout(() => {
            overlay.classList.remove('show');
        }, 2000);

        new TWEEN.Tween(camera.position)
            .to({ x: 0, y: -155, z: -40 }, 1000)
            .delay(1000)
            .easing(TWEEN.Easing.Back.In)
            .start()

        setTimeout(() => {
            controls.minPolarAngle = Math.PI / 3;
            controls.maxPolarAngle = Math.PI / 2.15;
            // Set the minimum and maximum azimuth angle to limit camera's movement
            controls.minAzimuthAngle = 2;
            controls.maxAzimuthAngle = 3.13;
            controls.minDistance = 28;
            controls.maxDistance = 38;
            controls.target.set(0, -160, 0);
            scene.visible = false;
            scene2.visible = true;
            interior_zstore.visible = true;
            btnbox1e.visible = false;
        }, 1000);
    }

    var intersects = raycaster.intersectObjects([btnbox1e]);
    if (intersects.length > 0) {

        // Show overlay
        const overlay = document.querySelector('.overlay');
        overlay.classList.add('show');

        // Hide overlay after a delay
        setTimeout(() => {
            overlay.classList.remove('show');
        }, 2000);

        new TWEEN.Tween(camera.position)
            .to({ x: 66, y: 19, z: -1 }, 1000)
            .delay(1000)
            .easing(TWEEN.Easing.Back.In)
            .start()

        setTimeout(() => {
            controls.minPolarAngle = Math.PI / 3.90;
            controls.maxPolarAngle = Math.PI / 2.15;
            //to remove the minimum and maximum azimuth angles
            controls.minAzimuthAngle = undefined;
            controls.maxAzimuthAngle = undefined;
            controls.minDistance = 55;
            controls.maxDistance = 80;
            controls.target.set(0, 0, 0);
            scene.visible = true;
            scene2.visible = false;
            interior_zstore.visible = false;
            btnbox1e.visible = false;
        }, 1000);

    }



    // Button Function: Tazte Button --------------------------------------------------------------------------------------------------------------------------------------------------------------
    var intersects = raycaster.intersectObjects([btnbox2]);
    if (intersects.length > 0) {

        // Show overlay
        const overlay = document.querySelector('.overlay');
        overlay.classList.add('show');

        // Hide overlay after a delay
        setTimeout(() => {
            overlay.classList.remove('show');
        }, 2000);

        new TWEEN.Tween(camera.position)
            .to({ x: 0, y: -205, z: -40 }, 1000)
            .delay(1000)
            .easing(TWEEN.Easing.Cubic.Out)
            .start()

        setTimeout(() => {
            controls.minPolarAngle = Math.PI / 3;
            controls.maxPolarAngle = Math.PI / 2.15;
            // Set the minimum and maximum azimuth angle to limit camera's movement
            controls.minAzimuthAngle = 2;
            controls.maxAzimuthAngle = 3.13;
            controls.minDistance = 28;
            controls.maxDistance = 38;
            controls.target.set(0, -210, 0);
            scene.visible = false;
            scene2.visible = true;
            interior_tazte.visible = true;
            btnbox2e.visible = false;
        }, 1000);
    }

    var intersects = raycaster.intersectObjects([btnbox2e]);
    if (intersects.length > 0) {

        // Show overlay
        const overlay = document.querySelector('.overlay');
        overlay.classList.add('show');

        // Hide overlay after a delay
        setTimeout(() => {
            overlay.classList.remove('show');
        }, 2000);

        new TWEEN.Tween(camera.position)
            .to({ x: 29, y: 19, z: -59 }, 1000)
            .delay(1000)
            .easing(TWEEN.Easing.Cubic.Out)
            .start()

        setTimeout(() => {
            controls.minPolarAngle = Math.PI / 3.90;
            controls.maxPolarAngle = Math.PI / 2.15;
            //to remove the minimum and maximum azimuth angles
            controls.minAzimuthAngle = undefined;
            controls.maxAzimuthAngle = undefined;
            controls.minDistance = 55;
            controls.maxDistance = 80;
            controls.target.set(0, 0, 0);
            scene.visible = true;
            scene2.visible = false;
            interior_tazte.visible = false;
            btnbox2e.visible = false;
        }, 1000);
    }

    // Button Function: Enogy Button --------------------------------------------------------------------------------------------------------------------------------------------------------------
    var intersects = raycaster.intersectObjects([btnbox3]);
    if (intersects.length > 0) {

        // Show overlay
        const overlay = document.querySelector('.overlay');
        overlay.classList.add('show');

        // Hide overlay after a delay
        setTimeout(() => {
            overlay.classList.remove('show');
        }, 2000);

        new TWEEN.Tween(camera.position)
            .to({ x: 0, y: -256.170, z: -40 }, 1000)
            .delay(1000)
            .easing(TWEEN.Easing.Cubic.Out)
            .start()

        setTimeout(() => {
            controls.minPolarAngle = Math.PI / 3;
            controls.maxPolarAngle = Math.PI / 2.15;
            // Set the minimum and maximum azimuth angle to limit camera's movement
            controls.minAzimuthAngle = 2;
            controls.maxAzimuthAngle = 3.13;
            controls.minDistance = 28;
            controls.maxDistance = 38;
            controls.target.set(0, -270, 0);
            scene.visible = false;
            scene2.visible = true;
            interior_enogy.visible = true;
            btnbox3e.visible = false;
        }, 1000);
    }

    var intersects = raycaster.intersectObjects([btnbox3e]);
    if (intersects.length > 0) {

        // Show overlay
        const overlay = document.querySelector('.overlay');
        overlay.classList.add('show');

        // Hide overlay after a delay
        setTimeout(() => {
            overlay.classList.remove('show');
        }, 2000);

        new TWEEN.Tween(camera.position)
            .to({ x: -46, y: 19, z: -54 }, 1000)
            .delay(1000)
            .easing(TWEEN.Easing.Cubic.Out)
            .start()


        setTimeout(() => {
            controls.minPolarAngle = Math.PI / 3.90;
            controls.maxPolarAngle = Math.PI / 2.15;
            //to remove the minimum and maximum azimuth angles
            controls.minAzimuthAngle = undefined;
            controls.maxAzimuthAngle = undefined;
            controls.minDistance = 55;
            controls.maxDistance = 80;
            controls.target.set(0, 0, 0);
            scene.visible = true;
            scene2.visible = false;
            interior_enogy.visible = false;

        }, 1000);
    }

    // Button Function: Join Us Button --------------------------------------------------------------------------------------------------------------------------------------------------------------
    var intersects = raycaster.intersectObjects([btnbox4]);
    if (intersects.length > 0) {

        // Show overlay
        const overlay = document.querySelector('.overlay');
        overlay.classList.add('show');

        // Hide overlay after a delay
        setTimeout(() => {
            overlay.classList.remove('show');
        }, 2000);

        new TWEEN.Tween(camera.position)
            .to({ x: 0, y: -305, z: -40 }, 1000)
            .delay(1000)
            .easing(TWEEN.Easing.Cubic.Out)
            .start()

        setTimeout(() => {
            controls.minPolarAngle = Math.PI / 3;
            controls.maxPolarAngle = Math.PI / 2.15;
            // Set the minimum and maximum azimuth angle to limit camera's movement
            controls.minAzimuthAngle = 2;
            controls.maxAzimuthAngle = 3.13;
            controls.minDistance = 28;
            controls.maxDistance = 38;
            controls.target.set(0, -310, 0);
            scene.visible = false;
            scene2.visible = true;
            interior_joinus.visible = true;
            btnbox4e.visible = false;
        }, 1000);
    }

    var intersects = raycaster.intersectObjects([btnbox4e]);
    if (intersects.length > 0) {

        // Show overlay
        const overlay = document.querySelector('.overlay');
        overlay.classList.add('show');

        // Hide overlay after a delay
        setTimeout(() => {
            overlay.classList.remove('show');
        }, 2000);

        new TWEEN.Tween(camera.position)
            .to({ x: -32, y: 19, z: 66 }, 1000)
            .delay(1000)
            .easing(TWEEN.Easing.Cubic.Out)
            .start()

        setTimeout(() => {
            controls.minPolarAngle = Math.PI / 3.90;
            controls.maxPolarAngle = Math.PI / 2.15;
            //to remove the minimum and maximum azimuth angles
            controls.minAzimuthAngle = undefined;
            controls.maxAzimuthAngle = undefined;
            controls.minDistance = 55;
            controls.maxDistance = 80;
            controls.target.set(0, 0, 0);
            scene.visible = true;
            scene2.visible = false;
            interior_joinus.visible = false;
            btnbox4e.visible = false;
        }, 1000);
    }

    // Button Function: Z Mmber Button --------------------------------------------------------------------------------------------------------------------------------------------------------------
    var intersects = raycaster.intersectObjects([btnbox5]);
    if (intersects.length > 0) {

        // Show overlay
        const overlay = document.querySelector('.overlay');
        overlay.classList.add('show');

        // Hide overlay after a delay
        setTimeout(() => {
            overlay.classList.remove('show');
        }, 2000);

        new TWEEN.Tween(camera.position)
            .to({ x: 0, y: -355, z: -40 }, 1000)
            .delay(1000)
            .easing(TWEEN.Easing.Cubic.Out)
            .start()

        setTimeout(() => {
            controls.minPolarAngle = Math.PI / 3;
            controls.maxPolarAngle = Math.PI / 2.15;
            // Set the minimum and maximum azimuth angle to limit camera's movement
            controls.minAzimuthAngle = 2;
            controls.maxAzimuthAngle = 3.13;
            controls.minDistance = 28;
            controls.maxDistance = 38;
            controls.target.set(0, -360, 0);
            scene.visible = false;
            scene2.visible = true;
            interior_zmember.visible = true;
            btnbox5e.visible = false;
        }, 1000);
    }

    var intersects = raycaster.intersectObjects([btnbox5e]);
    if (intersects.length > 0) {

        // Show overlay
        const overlay = document.querySelector('.overlay');
        overlay.classList.add('show');

        // Hide overlay after a delay
        setTimeout(() => {
            overlay.classList.remove('show');
        }, 2000);

        new TWEEN.Tween(camera.position)
            .to({ x: 23, y: 19, z: 63 }, 1000)
            .delay(1000)
            .easing(TWEEN.Easing.Cubic.Out)
            .start()

        setTimeout(() => {
            controls.minPolarAngle = Math.PI / 3.90;
            controls.maxPolarAngle = Math.PI / 2.15;
            //to remove the minimum and maximum azimuth angles
            controls.minAzimuthAngle = undefined;
            controls.maxAzimuthAngle = undefined;
            controls.minDistance = 55;
            controls.maxDistance = 80;
            controls.target.set(0, 0, 0);
            scene.visible = true;
            scene2.visible = false;
            interior_zmember.visible = false;
            btnbox5e.visible = false;
        }, 1000);
    }


    // Check if any of the mesh objects were clicked
    const intersectspopup1 = raycaster.intersectObject(popupMessage1);
    if (intersectspopup1.length > 0) {
        showPopupForMesh1(popupMessage1);
        event.stopPropagation();
        return;
    }

    const intersectspopup2 = raycaster.intersectObject(popupMessage2);
    if (intersectspopup2.length > 0) {
        showPopupForMesh2(popupMessage2);
        event.stopPropagation();
        return;
    }

    const intersectspopup3 = raycaster.intersectObject(popupMessage3);
    if (intersectspopup3.length > 0) {
        showPopupForMesh3(popupMessage3);
        event.stopPropagation();
        return;
    }

    const intersectspopup4 = raycaster.intersectObject(popupMessage4);
    if (intersectspopup4.length > 0) {
        showPopupForMesh4(popupMessage4);
        event.stopPropagation();
        return;
    }

    const intersectspopup5 = raycaster.intersectObject(popupMessage5);
    if (intersectspopup5.length > 0) {
        showPopupForMesh5(popupMessage5);
        event.stopPropagation();
        return;
    }


    
};

// Z Store Modal Content
function showPopupForMesh1() {
    var popupContainer = document.createElement('div');
    popupContainer.className = 'popup-container';
    document.body.appendChild(popupContainer);

    var popupContent = document.createElement('div');
    popupContent.className = 'popup-wrapper';

    var popupHTML = `
        <img class="img-fluid d-block mx-auto position-absolute top-0 start-50 translate-middle z-logo" src="../img/zcity/z-img.png" alt="Image" />

        <div class="popup-content">
            <div class="popup-header zcity-style"></div>

            <div class="popup-body">
                <img class="img-fluid d-block mx-auto mb-4" src="../img/zcity/logo-zstore.png" alt="Image" />

                <h3 class="text-capitalize mb-3 fw-bold">Welcome to the Zstore!</h3>

                <div class="popup-content-scroll">
                    <p class="text-justify">
                        Experience guilt-free online shopping through Zstore and buy products at great prices. Enjoy discounts on your favorite brands, e-vouchers, and cheaper meals with family or friends!
                    </p>
                    
                    <!-- <p class="text-justify">
                        Check out it 
                        <a class="text-decoration-none link-primary" href="https://zcity.io/zstore" target="_blank">
                            Zstore
                        </a>
                        to Explore More Deal!
                    </p> -->

                </div>

                <button class="btn-zcity mt-3 mb-5 w-100">Close</button>
            </div>

            <div class="popup-footer zcity-footer position-absolute bottom-0"></div>
        </div>
    `;

    popupContent.innerHTML = popupHTML;

    popupContainer.appendChild(popupContent);

    var popupContainerStyle = document.createElement('style');
    // popupContainerStyle.textContent = `
    //     .popup-content {
    //         max-height: 400px;
    //         overflow-y: scroll;
    //         margin-bottom: 10px;
    //     }
    // `;
    document.head.appendChild(popupContainerStyle);

    var popupCloseBtn = popupContent.querySelector('button');
    popupCloseBtn.addEventListener('click', function () {
        document.body.removeChild(popupContainer);
    });

    popupContainer.addEventListener('click', function (event) {
        if (event.target === this) {
            document.body.removeChild(this);
        }
    });
}

// Tazte Modal Content
function showPopupForMesh2() {
    var popupContainer = document.createElement('div');
    popupContainer.className = 'popup-container';
    document.body.appendChild(popupContainer);

    var popupContent = document.createElement('div');
    popupContent.className = 'popup-wrapper';

    var popupHTML = `
        <img class="img-fluid d-block mx-auto position-absolute top-0 start-50 translate-middle z-logo" src="../img/tazte/z-img.png" alt="Image" />

        <div class="popup-content">
            <div class="popup-header tazte-style"></div>

            <div class="popup-body">
                <img class="img-fluid d-block mx-auto mb-4" src="../img/tazte/logo.png" alt="Image" />

                <h3 class="text-capitalize mb-3 fw-bold">Welcome to the TAZTE!</h3>

                <div class="popup-content-scroll">
                    <p class="text-justify">
                        An all-in-one F&B digital platform with an automated business solution and management system!
                    </p>

                    <p class="text-justify">
                        Discover the power of our advanced F&B system. Benefit from our free consultation and unlock endless possibilities for your business. Come 
                        <a class="text-decoration-none link-warning" href="https://tazte.co/" target="_blank">
                            TAZTE
                        </a>
                        to explore more today!
                    </p>
                </div>

                <img class="img-fluid d-block mx-auto mb-1" src="../img/tazte/tazte-cat.png" alt="Image" />

                <button class="btn-tazte mt-4 mb-5 w-100">Close</button>
            </div>

            <div class="popup-footer tazte-footer position-absolute bottom-0"></div>
        </div>
    `;

    popupContent.innerHTML = popupHTML;

    popupContainer.appendChild(popupContent);

    var popupContainerStyle = document.createElement('style');
    // popupContainerStyle.textContent = `
    //     .popup-content {
    //         max-height: 400px;
    //         overflow-y: scroll;
    //         margin-bottom: 10px;
    //     }
    // `;
    document.head.appendChild(popupContainerStyle);

    var popupCloseBtn = popupContent.querySelector('button');
    popupCloseBtn.addEventListener('click', function () {
        document.body.removeChild(popupContainer);
    });

    popupContainer.addEventListener('click', function (event) {
        if (event.target === this) {
            document.body.removeChild(this);
        }
    });
}

// Enogy Modal Content
function showPopupForMesh3() {
    var popupContainer = document.createElement('div');
    popupContainer.className = 'popup-container';
    document.body.appendChild(popupContainer);

    var popupContent = document.createElement('div');
    popupContent.className = 'popup-wrapper';

    var popupHTML = `
        <img class="img-fluid d-block mx-auto position-absolute top-0 start-50 translate-middle z-logo" src="../img/enogy/z-img.png" alt="Image" />

        <div class="popup-content">
            <div class="popup-header enogy-style"></div>

            <div class="popup-body">
                <img class="img-fluid d-block mx-auto mb-4" src="../img/enogy/logo.png" alt="Image" />

                <h3 class="text-capitalize mb-3 fw-bold">Welcome to the ENOGY!</h3>

                <div class="popup-content-scroll">
                    <p class="text-justify">
                        enogy aims to deliver premium, high-quality health food products, while conducting research to develop the world's finest formula. We exclusively endorse products that adhere to stringent manufacturing standards, and have undergone rigorous quality inspections.
                    </p>

                    <p class="text-justify">
                        enogy specializes in selling and marketing health products, ensuring that all our products are manufactured by experienced product developers and quality control personnel.
                    </p>

                    <p class="text-justify">
                        Boost your immune system with our natural health supplement, packed with essential vitamins and antioxidants for optimal well-being. 
                        <a class="text-decoration-none link-info" href="https://enogy.asia/en/index.html" target="_blank">
                            Explore & Order
                        </a>
                        now for a healthier you!
                    </p>

                </div>

                <button class="btn-enogy mt-4 mb-5 w-100">Close</button>
            </div>

            <div class="popup-footer enogy-footer position-absolute bottom-0"></div>
        </div>  
    `;


    popupContent.innerHTML = popupHTML;

    popupContainer.appendChild(popupContent);

    var popupContainerStyle = document.createElement('style');
    // popupContainerStyle.textContent = `
    //     .popup-content {
    //         max-height: 400px;
    //         overflow-y: scroll;
    //         margin-bottom: 10px;
    //     }
    // `;
    document.head.appendChild(popupContainerStyle);

    var popupCloseBtn = popupContent.querySelector('button');
    popupCloseBtn.addEventListener('click', function () {
        document.body.removeChild(popupContainer);
    });

    popupContainer.addEventListener('click', function (event) {
        if (event.target === this) {
            document.body.removeChild(this);
        }
    });
}

// Carrer Modal Content
// function showPopupForMesh4() {
//     var popupContainer = document.createElement('div');
//     popupContainer.className = 'popup-container';
//     document.body.appendChild(popupContainer);

//     var popupContent = document.createElement('div');
//     popupContent.className = 'popup-wrapper';

//     var popupHTML = `
//         <img class="img-fluid d-block mx-auto position-absolute top-0 start-50 translate-middle z-logo" src="../img/zcity/z-img.png" alt="Image" />

//         <div class="popup-content">
//             <div class="popup-header zcity-style"></div>

//             <div class="popup-body">
//                 <img class="img-fluid d-block mx-auto mb-4" src="../img/zcity/logo.png" alt="Image" />

//                 <h3 class="text-capitalize mb-3 fw-bold">Welcome to the ZCITY!</h3>

//                 <div class="popup-content-scroll">
//                     <p class="text-justify">
//                         The ZCITY app is here to revolutionize your e-payment gateway experience, making it effortless and rewarding. Say goodbye to the complexities of traditional payment methods and embrace a seamless, user-friendly app that puts great deals, increased earnings, and brilliant rewards right at your fingertips.
//                     </p>

//                     <p class="text-justify">
//                         Find out all about careers at
//                         <a class="text-decoration-none link-primary" href="https://zcity.io/career" target="_blank">
//                             ZCITY
//                         </a>
//                         , for everyone from graduates to those with years of experience. 
//                     </p>

//                 </div>

//                 <button class="btn-zcity mt-4 mb-5 w-100">Close</button>
//             </div>

//             <div class="popup-footer zcity-footer  position-absolute bottom-0"></div>
//         </div> 
//     `;


//     popupContent.innerHTML = popupHTML;

//     popupContainer.appendChild(popupContent);


//     var popupContainerStyle = document.createElement('style');
//     // popupContainerStyle.textContent = `
//     //     .popup-content {
//     //         max-height: 400px;
//     //         overflow-y: scroll;
//     //         margin-bottom: 10px;
//     //     }
//     // `;
//     document.head.appendChild(popupContainerStyle);


//     var popupCloseBtn = popupContent.querySelector('button');
//     popupCloseBtn.addEventListener('click', function () {
//         document.body.removeChild(popupContainer);
//     });

//     popupContainer.addEventListener('click', function (event) {
//         if (event.target === this) {
//             document.body.removeChild(this);
//         }
//     });
// }

// Z Member Modal Content
function showPopupForMesh5() {
    var popupContainer = document.createElement('div');
    popupContainer.className = 'popup-container';
    document.body.appendChild(popupContainer);

    var popupContent = document.createElement('div');
    popupContent.className = 'popup-wrapper';

    var popupHTML = `
        <img class="img-fluid d-block mx-auto position-absolute top-0 start-50 translate-middle z-logo" src="../img/zcity/z-img.png" alt="Image" />

        <div class="popup-content">
            <div class="popup-header zcity-style"></div>

            <div class="popup-body">
                <img class="img-fluid d-block mx-auto mb-4" src="../img/zcity/logo-zmember.png" alt="Image" />

                <h3 class="text-capitalize mb-3 fw-bold">Welcome to the Premium Store!</h3>

                <div class="popup-content-scroll">
                    <p class="text-justify">
                        Welcome to the Premium Store! Indulge in exclusive rewards and significant savings by choosing Premium Store bundles!
                    </p>
                    
                    <p class="text-justify">
                        Get exclusive rewards and big savings with our Premium Store bundles! Choose Premium Store Bundles for extra discounts on any bill payments and e-vouchers.
                    </p>
                    
                    <!-- <p class="text-justify">
                        Sign up ZCITY membership program - 
                        <a class="text-decoration-none link-primary" href="https://zcity.io/career" target="_blank">
                            Zmember
                        </a>
                        to get more special offer and others benefit.
                    </p> -->

                </div>

                <button class="btn-zcity mt-3 mb-5 w-100">Close</button>
            </div>

            <div class="popup-footer zcity-footer position-absolute bottom-0"></div>
        </div>  
    `;


    popupContent.innerHTML = popupHTML;

    popupContainer.appendChild(popupContent);

    var popupContainerStyle = document.createElement('style');
    // popupContainerStyle.textContent = `
    //     .popup-content {
    //         max-height: 400px;
    //         overflow-y: scroll;
    //         margin-bottom: 10px;
    //     }
    // `;
    document.head.appendChild(popupContainerStyle);

    var popupCloseBtn = popupContent.querySelector('button');
    popupCloseBtn.addEventListener('click', function () {
        document.body.removeChild(popupContainer);
    });

    popupContainer.addEventListener('click', function (event) {
        if (event.target === this) {
            document.body.removeChild(this);
        }
    });
}

// Modal Btn Box
const popupGeometry = new THREE.BoxGeometry(1.6, 1.6, 1.6);
const popupMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 });

// Z Store Modal Btn
const popupMessage1 = new THREE.Mesh(popupGeometry, popupMaterial);
popupMessage1.position.set(1.5, -167.25, -7.8);
scene2.add(popupMessage1);
popupMessage1.visible = false;

// Tazte Modal Btn
const popupMessage2 = new THREE.Mesh(popupGeometry, popupMaterial);
popupMessage2.position.set(3.45, -214.3, -9.13);
scene2.add(popupMessage2);
popupMessage2.visible = false;

// Enogy Modal Btn
const popupMessage3 = new THREE.Mesh(popupGeometry, popupMaterial);
popupMessage3.position.set(5.75, -269, 1.35);
scene2.add(popupMessage3);
popupMessage3.visible = false;

// Carrer Modal Btn
const popupMessage4 = new THREE.Mesh(popupGeometry, popupMaterial);
popupMessage4.position.set(4.25, -314.3, -8.5);
scene2.add(popupMessage4);
popupMessage4.visible = false;

// Z Member Modal Btn
const popupMessage5 = new THREE.Mesh(popupGeometry, popupMaterial);
popupMessage5.position.set(1.4, -363.8, 5.5);
scene2.add(popupMessage5);
popupMessage5.visible = false;


var popupContainer = document.getElementById('popup-container');

var popupCloseBtn = document.getElementById('popup-close-btn'); 
popupCloseBtn.addEventListener('click', function () {
    popupContainer.style.display = 'none';
});


// BIllBoard
var videoGeometry = new THREE.PlaneGeometry(15, 10.5, 15);

var video = document.createElement('video');
video.src = '../video/video.mp4';
video.crossOrigin = 'anonymous';
video.loop = true;
video.muted = true;
video.play();

var videoTexture = new THREE.VideoTexture(video);
videoTexture.minFilter = THREE.LinearFilter;
videoTexture.magFilter = THREE.LinearFilter;
videoTexture.format = THREE.RGBAFormat;

var videoMaterial = new THREE.MeshBasicMaterial({ map: videoTexture });

var videoMesh = new THREE.Mesh(videoGeometry, videoMaterial);
videoMesh.position.set(-35.3, 19.2, 2.85);
videoMesh.rotateY(-1.58);

scene.add(videoMesh);



// Menu Bar Navigate to Interior Function ------------------------------------------------------------------------------------------------------------//
document.addEventListener('DOMContentLoaded', function () {
    document.querySelector('.home-btn').addEventListener('click', handleHomeButtonClick);

    document.querySelector('.b1-btn').addEventListener('click', handleB1ButtonClick);

    document.querySelector('.b2-btn').addEventListener('click', handleB2ButtonClick);

    document.querySelector('.b3-btn').addEventListener('click', handleB3ButtonClick);

    document.querySelector('.b4-btn').addEventListener('click', handleB4ButtonClick);

    document.querySelector('.b5-btn').addEventListener('click', handleB5ButtonClick);



    // Function for home-btn
    function handleHomeButtonClick() {
        // Show overlay
        const overlay = document.querySelector('.overlay');
        overlay.classList.add('show');

        // Hide overlay after a delay
        setTimeout(() => {
            overlay.classList.remove('show');
        }, 2000);

        new TWEEN.Tween(camera.position)
            .to({ x: 75, y: 19, z: 0 }, 0)
            .delay(1000)
            .easing(TWEEN.Easing.Back.In)
            .start();

        setTimeout(() => {
            controls.minPolarAngle = Math.PI / 3;
            controls.maxPolarAngle = Math.PI / 2;
            controls.minAzimuthAngle = -Infinity; // Set to the Smallest Possible Value
            controls.maxAzimuthAngle = Infinity; // Set to the Largest Possible Value
            controls.minDistance = 55;
            controls.maxDistance = 80;
            controls.target.set(0, 15, 0);
            scene.visible = true;
            scene2.visible = false;
        }, 1000);
    }

    // Function for b1-btn
    function handleB1ButtonClick() {
        // Show overlay
        const overlay = document.querySelector('.overlay');
        overlay.classList.add('show');

        // Hide overlay after a delay
        setTimeout(() => {
            overlay.classList.remove('show');
        }, 2000);

        new TWEEN.Tween(camera.position)
            .to({ x: 0, y: -155, z: -40 }, 1000)
            .delay(1000)
            .easing(TWEEN.Easing.Back.In)
            .start();

        setTimeout(() => {
            controls.minPolarAngle = Math.PI / 3;
            controls.maxPolarAngle = Math.PI / 2.15;
            controls.minAzimuthAngle = 2;
            controls.maxAzimuthAngle = 3.13;
            controls.minDistance = 28;
            controls.maxDistance = 38;
            controls.target.set(0, -160, 0);
            scene.visible = false;
            scene2.visible = true;
            interior_zstore.visible = true;
            interior_tazte.visible = false;
            interior_enogy.visible = false;
            interior_joinus.visible = false;
            interior_zmember.visible = false;
            btnbox1e.visible = false;
        }, 1000);
    }

    // Function for b2-btn
    function handleB2ButtonClick() {
        // Show overlay
        const overlay = document.querySelector('.overlay');
        overlay.classList.add('show');

        // Hide overlay after a delay
        setTimeout(() => {
            overlay.classList.remove('show');
        }, 2000);

        new TWEEN.Tween(camera.position)
            .to({ x: 0, y: -205, z: -40 }, 1000)
            .delay(1000)
            .easing(TWEEN.Easing.Cubic.Out)
            .start();

        setTimeout(() => {
            controls.minPolarAngle = Math.PI / 3;
            controls.maxPolarAngle = Math.PI / 2.15;
            controls.minAzimuthAngle = 2;
            controls.maxAzimuthAngle = 3.13;
            controls.minDistance = 28;
            controls.maxDistance = 38;
            controls.target.set(0, -210, 0);
            scene.visible = false;
            scene2.visible = true;
            interior_zstore.visible = false;
            interior_tazte.visible = true;
            interior_enogy.visible = false;
            interior_joinus.visible = false;
            interior_zmember.visible = false;
            btnbox2e.visible = false;
        }, 1000);
    }

    // Function for b3-btn
    function handleB3ButtonClick() {
        // Show overlay
        const overlay = document.querySelector('.overlay');
        overlay.classList.add('show');

        // Hide overlay after a delay
        setTimeout(() => {
            overlay.classList.remove('show');
        }, 2000);

        new TWEEN.Tween(camera.position)
            .to({ x: 0, y: -256.17, z: -40 }, 1000)
            .delay(1000)
            .easing(TWEEN.Easing.Cubic.Out)
            .start();

        setTimeout(() => {
            controls.minPolarAngle = Math.PI / 3;
            controls.maxPolarAngle = Math.PI / 2.15;
            controls.minAzimuthAngle = 2;
            controls.maxAzimuthAngle = 3.13;
            controls.minDistance = 28;
            controls.maxDistance = 38;
            controls.target.set(0, -270, 0);
            scene.visible = false;
            scene2.visible = true;
            interior_zstore.visible = false;
            interior_tazte.visible = false;
            interior_enogy.visible = true;
            interior_joinus.visible = false;
            interior_zmember.visible = false;
            btnbox3e.visible = false;
        }, 1000);
    }

    // Function for b4-btn
    function handleB4ButtonClick() {
        // Show overlay
        const overlay = document.querySelector('.overlay');
        overlay.classList.add('show');

        // Hide overlay after a delay
        setTimeout(() => {
            overlay.classList.remove('show');
        }, 2000);

        new TWEEN.Tween(camera.position)
            .to({ x: 0, y: -305, z: -40 }, 1000)
            .delay(1000)
            .easing(TWEEN.Easing.Cubic.Out)
            .start();

        setTimeout(() => {
            controls.minPolarAngle = Math.PI / 3;
            controls.maxPolarAngle = Math.PI / 2.15;
            controls.minAzimuthAngle = 2;
            controls.maxAzimuthAngle = 3.13;
            controls.minDistance = 28;
            controls.maxDistance = 38;
            controls.target.set(0, -310, 0);
            scene.visible = false;
            scene2.visible = true;
            interior_zstore.visible = false;
            interior_tazte.visible = false;
            interior_enogy.visible = false;
            interior_joinus.visible = true;
            interior_zmember.visible = false;
            btnbox4e.visible = false;
        }, 1000);
    }

    // Function for b5-btn
    function handleB5ButtonClick() {
        // Show overlay
        const overlay = document.querySelector('.overlay');
        overlay.classList.add('show');

        // Hide overlay after a delay
        setTimeout(() => {
            overlay.classList.remove('show');
        }, 2000);

        new TWEEN.Tween(camera.position)
            .to({ x: 0, y: -355, z: -40 }, 1000)
            .delay(1000)
            .easing(TWEEN.Easing.Cubic.Out)
            .start();

        setTimeout(() => {
            controls.minPolarAngle = Math.PI / 3;
            controls.maxPolarAngle = Math.PI / 2.15;
            controls.minAzimuthAngle = 2;
            controls.maxAzimuthAngle = 3.13;
            controls.minDistance = 28;
            controls.maxDistance = 38;
            controls.target.set(0, -360, 0);
            scene.visible = false;
            scene2.visible = true;
            interior_zstore.visible = false;
            interior_tazte.visible = false;
            interior_enogy.visible = false;
            interior_joinus.visible = false;
            interior_zmember.visible = true;
            btnbox5e.visible = false;
        }, 1000);
    }

});



// Scroll / Main Character Walk Animation----------------------------------------------------------------------------------------------------------------//
// Scroll Function
let scrollSpeed = (function () {

    let lastPos, newPos, delta

    function clear() {
        lastPos = null;
        delta = 0;
    }
    clear();

    return function () {
        newPos = controls.getAzimuthalAngle();
        if (lastPos != null) {
            delta = newPos - lastPos;
        };
        if (delta == 1 || delta == -1) delta = 0;
        if (delta < -1) {
            delta = -delta;
        };
        //else if (delta > 1) character.rotation.z = 0;
        if (walkAction) walkAction.timeScale = delta * 200;

        lastPos = newPos;
        return delta;

    };
})();

// Tick ----------------------------------------------------------------------------------------------------------------//
let azimuthalAngle;
let characterPos = 0;

/*
 * RUN
 */
const doorshowclock = new THREE.Clock();
const doorhideclock = new THREE.Clock();
const vanclock = new THREE.Clock();

// Pointer
const pointer1clock = new THREE.Clock();
const pointer2clock = new THREE.Clock();
const pointer3clock = new THREE.Clock();
const pointer4clock = new THREE.Clock();
const pointer5clock = new THREE.Clock();

// Point Modal
const point_modal1clock = new THREE.Clock();
const point_modal2clock = new THREE.Clock();
const point_modal3clock = new THREE.Clock();
// const point_modal4clock = new THREE.Clock();
const point_modal5clock = new THREE.Clock();

// Building
const zcityDesign2clock = new THREE.Clock();
const spin_n_winclock = new THREE.Clock();
const fountainclock = new THREE.Clock();
const cloudclock = new THREE.Clock();

// Character
const charzcity2clock = new THREE.Clock();
const charMclock = new THREE.Clock();
const char1clock = new THREE.Clock();
const char2clock = new THREE.Clock();
const char3clock = new THREE.Clock();
const char4clock = new THREE.Clock();
const char5clock = new THREE.Clock();
const char6clock = new THREE.Clock();
const char7clock = new THREE.Clock();
const char8clock = new THREE.Clock();
const char9clock = new THREE.Clock();
const char10clock = new THREE.Clock();
const char11clock = new THREE.Clock();
const char12clock = new THREE.Clock();
const char13clock = new THREE.Clock();
const char14clock = new THREE.Clock();
const char15clock = new THREE.Clock();
const char16clock = new THREE.Clock();
const char17clock = new THREE.Clock();
const char18clock = new THREE.Clock();
const char19clock = new THREE.Clock();
const char20clock = new THREE.Clock();
const char21clock = new THREE.Clock();
const char22clock = new THREE.Clock();
const char23clock = new THREE.Clock();
const char24clock = new THREE.Clock();
const char25clock = new THREE.Clock();
const char26clock = new THREE.Clock();
const char27clock = new THREE.Clock();

// Interior
const i_zstoreclock = new THREE.Clock();
const i_tazteclock = new THREE.Clock();
const i_enogyclock = new THREE.Clock();
const i_joinusclock = new THREE.Clock();
const i_zmemberclock = new THREE.Clock();


const tick = () => {


    // Update Controls

    controls.update();

    azimuthalAngle = controls.getAzimuthalAngle();
    characterPos = azimuthalAngle / (Math.PI * 2);
    if (characterPos < 0) {
        characterPos = 0.5 + (0.5 + characterPos);
    };

    if (character) {
        character.position.x = Math.sin(azimuthalAngle) * 53;
        character.position.z = Math.cos(azimuthalAngle) * 53;
        character.rotation.y = azimuthalAngle;

    };

    // Animation Mixer
    const doorshowDelta = doorshowclock.getDelta();
    if (mixer_doorShow) mixer_doorShow.update(doorshowDelta);

    const doorhideDelta = doorhideclock.getDelta();
    if (mixer_doorHide) mixer_doorHide.update(doorhideDelta);

    if (mixer_zcityDesign2)
        mixer_zcityDesign2.update(zcityDesign2clock.getDelta());

    if (mixer_pointer1)
        mixer_pointer1.update(pointer1clock.getDelta());

    if (mixer_pointer2)
        mixer_pointer2.update(pointer2clock.getDelta());

    if (mixer_pointer3)
        mixer_pointer3.update(pointer3clock.getDelta());

    if (mixer_pointer4)
        mixer_pointer4.update(pointer4clock.getDelta());

    if (mixer_pointer5)
        mixer_pointer5.update(pointer5clock.getDelta());

    if (mixer_point_modal1)
        mixer_point_modal1.update(point_modal1clock.getDelta());

    if (mixer_point_modal2)
        mixer_point_modal2.update(point_modal2clock.getDelta());

    if (mixer_point_modal3)
        mixer_point_modal3.update(point_modal3clock.getDelta());

    // if (mixer_point_modal4)
    //     mixer_point_modal4.update(point_modal4clock.getDelta());

    if (mixer_point_modal5)
        mixer_point_modal5.update(point_modal5clock.getDelta());

    if (mixer_van)
        mixer_van.update(vanclock.getDelta());

    if (mixer_spin_n_win)
        mixer_spin_n_win.update(spin_n_winclock.getDelta());

    if (mixer_fountain)
        mixer_fountain.update(fountainclock.getDelta());

    if (mixer_cloud)
        mixer_cloud.update(cloudclock.getDelta());

    if (mixer_characterzcity2)
        mixer_characterzcity2.update(charzcity2clock.getDelta());

    if (mixer_character)
        mixer_character.update(charMclock.getDelta());

    if (mixer_character1)
        mixer_character1.update(char1clock.getDelta());

    if (mixer_character2)
        mixer_character2.update(char2clock.getDelta());

    if (mixer_character3)
        mixer_character3.update(char3clock.getDelta());

    if (mixer_character4)
        mixer_character4.update(char4clock.getDelta());

    if (mixer_character5)
        mixer_character5.update(char5clock.getDelta());

    // if (mixer_character6)
    //     mixer_character6.update(char6clock.getDelta());

    if (mixer_character7)
        mixer_character7.update(char7clock.getDelta());

    if (mixer_character8)
        mixer_character8.update(char8clock.getDelta());

    if (mixer_character9)
        mixer_character9.update(char9clock.getDelta());

    if (mixer_character10)
        mixer_character10.update(char10clock.getDelta());

    if (mixer_character11)
        mixer_character11.update(char11clock.getDelta());

    if (mixer_character12)
        mixer_character12.update(char12clock.getDelta());

    if (mixer_character13)
        mixer_character13.update(char13clock.getDelta());

    if (mixer_character14)
        mixer_character14.update(char14clock.getDelta());

    if (mixer_character15)
        mixer_character15.update(char15clock.getDelta());

    if (mixer_character16)
        mixer_character16.update(char16clock.getDelta());

    if (mixer_character17)
        mixer_character17.update(char17clock.getDelta());
    
    if (mixer_character18)
        mixer_character18.update(char18clock.getDelta());

    if (mixer_character19)
        mixer_character19.update(char19clock.getDelta());

    if (mixer_character20)
        mixer_character20.update(char20clock.getDelta());
    
    if (mixer_character21)
        mixer_character21.update(char21clock.getDelta());

    if (mixer_character22)
        mixer_character22.update(char22clock.getDelta());
    
    if (mixer_character23)
        mixer_character23.update(char23clock.getDelta());
    
    if (mixer_character24)
        mixer_character24.update(char24clock.getDelta());
    
    if (mixer_character25)
        mixer_character25.update(char25clock.getDelta());
    
    if (mixer_character26)
        mixer_character26.update(char26clock.getDelta());
    
    if (mixer_character27)
        mixer_character27.update(char27clock.getDelta());

    aimbox2.rotateY(0.002);

    // Interior Animation 
    if (mixer_interiorZstore)
        mixer_interiorZstore.update(i_zstoreclock.getDelta());
    
    if (mixer_interiorTazte)
        mixer_interiorTazte.update(i_tazteclock.getDelta());

    if (mixer_interiorEnogy)
        mixer_interiorEnogy.update(i_enogyclock.getDelta());

    if (mixer_interiorJoinUs)
        mixer_interiorJoinUs.update(i_joinusclock.getDelta());

    if (mixer_interiorZmember)
        mixer_interiorZmember.update(i_zmemberclock.getDelta());



    //Link with zLogo to facing camera
    aimbox3.rotation.y = Math.atan2((camera.position.x - aimbox3.position.x), (camera.position.z - aimbox3.position.z));

    scrollSpeed();

    TWEEN.update();

    // annotation();

    // Light follow camera
    // pointLight1.position.copy( camera.position );
    renderer.render(scene, camera);

    composer.render(scene, camera);
    renderer.render(scene2, camera);

    window.requestAnimationFrame(tick);

};
tick();

// Resize Window / Update sizes & camera & renderer----------------------------------------------------------------------------------------------------------------//
/**
 * Resize Window
 */
window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});


